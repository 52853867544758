import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { generateOTP, getFiles, verifyOTP } from "../api";
import { useNavigate } from "react-router-dom";

const Analysis = () => {
  const navigate = useNavigate();
  const filesRef = useRef();
  const [mobileNo, setMobileNo] = useState(null);
  const [OTP, setOTP] = useState("");
  const [isOTP, setIsOTP] = useState(false);
  const [OTPId, setOTPId] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    mobileNo: "",
  });

  useEffect(() => {
    if (mobileNo != null) {
      if (!mobileNo.match("^[0-9]{10}$")) {
        setValidationErrors({
          ...validationErrors,
          mobileNo: "Invalid Mobile No",
        });
      } else {
        setValidationErrors({ ...validationErrors, mobileNo: "" });
      }
    }
  }, [mobileNo]);

  const otpGenerate = () => {
    generateOTP(mobileNo, "final").then((res) => setOTPId(res.id));
  };

  const OTPVerify = (otp, id) => {
    verifyOTP(otp, id).then((res) => {
      if (res.success) {
        getFiles(mobileNo).then(({ files }) => {
          const tempFiles = files.slice(0, -1);
          tempFiles.split(",").map((item) => {
            filesRef.current.href = item;
            filesRef.current.click();
          });
          navigate("/");
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          otp: res.message,
        });
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Welcome - Analysis</title>
        <meta content="" name="description" />
        <meta content="" name="keywords" />
      </Helmet>
      <a
        ref={filesRef}
        href="/"
        target="_blank"
        rel="noreferrer"
        style={{ display: "hidden" }}
      >
        link
      </a>
      <main
        id="main"
        className="container"
        style={{ minHeight: "100vh", padding: "6% 0" }}
      >
        <section className="w-50" style={{ margin: "0 auto" }}>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <p className="m-0">Phone Name: </p>
            <div style={styles.fieldCol}>
              <input
                name="phone-number"
                className="form-control"
                placeholder="10 Digits Phone Number"
                value={mobileNo}
                disabled={isOTP}
                style={isOTP === true ? { background: "lightgrey" } : {}}
                onChange={(e) => setMobileNo(e.target.value)}
              />
              <span style={styles.error}>{validationErrors.mobileNo}</span>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              style={{ width: "60%", marginBottom: "5rem" }}
              type="button"
              className="btn btn-primary"
              disabled={
                mobileNo === "" ||
                mobileNo === null ||
                validationErrors.mobileNo !== "" ||
                isOTP === true
              }
              onClick={() => {
                setIsOTP(true);
                otpGenerate();
              }}
            >
              Submit
            </button>
          </div>
          {isOTP && (
            <>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="m-0">OTP: </p>
                <div style={styles.fieldCol}>
                  <input
                    name="otp"
                    className="form-control"
                    placeholder="Please enter OTP"
                    value={OTP}
                    disabled={!isOTP}
                    style={isOTP === false ? { background: "lightgrey" } : {}}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  {/* <span style={styles.error}>{validationErrors.otp}</span> */}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button
                  style={{ width: "60%" }}
                  type="button"
                  className="btn btn-primary"
                  disabled={OTP === ""}
                  onClick={() => OTPVerify(OTP, OTPId)}
                >
                  Submit
                </button>
              </div>
            </>
          )}
        </section>
      </main>
    </>
  );
};

const styles = {
  fieldCol: {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    width: "350px",
    overflow: "hidden",
    fontSize: "12px",
  },
  error: {
    color: "red",
    fontSize: "12px",
  },
};

export default Analysis;
