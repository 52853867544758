import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useHistory, useLocation } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  console.log(location.pathname);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [drawer, setDrawer] = useState(false);
  const closeDawer = () => setDrawer(false);
  const openDrawer = () => setDrawer(true);
  const activeLinkStyles = {
    color: "#015FC9", // Set the color for the active link
  };

  return (
    <>
      <nav id="navbar" className="navbar">
        <ul>
          <li>
            <NavLink to="/commercial-insurance">Commercial Insurance</NavLink>
          </li>
          <li className="dropdown">
            <NavLink to="/#">
              <span>Professional</span> <i className="bi bi-chevron-down"></i>
            </NavLink>
            <ul>
              <li>
                <NavLink to="/package-products">Package Products</NavLink>
              </li>
              <li>
                <NavLink to="/professional-indemnity">
                  Professional Indemnity
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/employee-benefit">Employee Benefit</NavLink>
          </li>
          <li>
            <NavLink to="/personal-insurance">Personal Insurance</NavLink>
          </li>
          <li>
            <NavLink to="/specialized">Specialized</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact</NavLink>
          </li>
          <li>
            <NavLink to="/analysis">View Gap Analysis</NavLink>
          </li>
          <form className="form-inline topLoginBtn my-2 my-lg-0">
            <NavLink
              onClick={handleShow}
              type="button"
              className="insurance"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#bookcall"
            >
              Book a free call
            </NavLink>
          </form>
        </ul>

        <i className="bi bi-list mobile-nav-toggle" onClick={openDrawer}></i>
      </nav>

      <Modal show={drawer} onHide={closeDawer}>
        <Modal.Body className="modal-body" style={{ backgroundColor: "white" }}>
          <button
            onClick={closeDawer}
            style={{ marginTop: "8px" }}
            type="button"
            className="btn-close pull-right"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <NavLink
            onClick={closeDawer}
            style={{
              color:
                location.pathname == "/commercial-insurance"
                  ? "#015FC9"
                  : "grey",
              fontWeight: "bold",
              lineHeight: "3",
            }}
            to="/commercial-insurance"
          >
            Commercial Insurance
          </NavLink>{" "}
          <br></br>
          <NavLink
            to="/#"
            style={{ color: "grey", fontWeight: "bold", lineHeight: "2" }}
          >
            <span>Professional</span> <i className="bi bi-chevron-down"></i>
          </NavLink>
          <br></br>
          <ul>
            <li>
              <NavLink
                onClick={closeDawer}
                style={{
                  color:
                    location.pathname == "/package-products"
                      ? "#015FC9"
                      : "grey",
                  fontWeight: "bold",
                }}
                to="/package-products"
              >
                Package Products
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={closeDawer}
                style={{
                  color:
                    location.pathname == "/professional-indemnity"
                      ? "#015FC9"
                      : "grey",
                  fontWeight: "bold",
                }}
                to="/professional-indemnity"
              >
                Professional Indemnity
              </NavLink>
            </li>
          </ul>
          <NavLink
            onClick={closeDawer}
            style={{
              color:
                location.pathname == "/employee-benefit" ? "#015FC9" : "grey",
              fontWeight: "bold",
              lineHeight: "2.5",
            }}
            to="/employee-benefit"
          >
            Employee Benefit
          </NavLink>
          <br></br>
          <NavLink
            onClick={closeDawer}
            style={{
              color:
                location.pathname == "/personal-insurance" ? "#015FC9" : "grey",
              fontWeight: "bold",
              lineHeight: "2.5",
            }}
            to="/personal-insurance"
          >
            Personal Insurance
          </NavLink>
          <br></br>
          <NavLink
            onClick={closeDawer}
            style={{
              color: location.pathname == "/specialized" ? "#015FC9" : "grey",
              fontWeight: "bold",
              lineHeight: "2.5",
            }}
            to="/specialized"
          >
            Specialized
          </NavLink>
          <br></br>
          <NavLink
            onClick={closeDawer}
            style={{
              color: location.pathname == "/contact-us" ? "#015FC9" : "grey",
              fontWeight: "bold",
              lineHeight: "2.5",
            }}
            to="/contact-us"
          >
            Contact
          </NavLink>
          <br></br>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Book an expert call
          </h5>
          <button
            onClick={handleClose}
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body className="modal-body contact">
          <form
            style={{ boxShadow: "none", padding: "12px 6px" }}
            action="#"
            method="post"
            role="form"
            id="modal_form"
            className="php-email-form"
          >
            <div className="row">
              <div className="col-md-12 form-group">
                <input
                  type="text"
                  name="modal_name"
                  className="form-control"
                  id="modal_name"
                  placeholder="Your Name"
                />
                <span className="text-danger modal_name_err"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <input
                  type="text"
                  className="form-control"
                  name="modal_phone_no"
                  id="modal_phone_no"
                  placeholder="Phone Number"
                />
                <span className="text-danger modal_phone_no_err"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label for="from">Preferred time</label>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label for="from">from</label>
                <select
                  className="form-control"
                  name="modal_time_from"
                  id="modal_time_from"
                >
                  <option value="">--Select--</option>
                  <option value="09">9:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                  <option value="19">19:00</option>
                  <option value="20">20:00</option>
                  <option value="21">21:00</option>
                </select>
                <span className="text-danger modal_time_from_err"></span>
              </div>
              <div className="col-md-6 form-group">
                <label for="from">to</label>
                <select
                  className="form-control"
                  name="modal_time_to"
                  id="modal_time_to"
                >
                  <option value="">--Select--</option>
                  <option value="09">9:00</option>
                  <option value="10">10:00</option>
                  <option value="11">11:00</option>
                  <option value="12">12:00</option>
                  <option value="13">13:00</option>
                  <option value="14">14:00</option>
                  <option value="15">15:00</option>
                  <option value="16">16:00</option>
                  <option value="17">17:00</option>
                  <option value="18">18:00</option>
                  <option value="19">19:00</option>
                  <option value="20">20:00</option>
                  <option value="21">21:00</option>
                </select>
                <span className="text-danger modal_time_to_err"></span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  placeholder="Message"
                ></textarea>
                <span className="text-danger message_err"></span>
              </div>
            </div>
            <div className="alert">
              <div className="modal_loading alert">Please wait....</div>
              <div className="modal_error-message alert">
                Message can't be sent. - this will change to - Oops Seem like
                there is an issue, Please check back soon !
              </div>
            </div>
            <div className="text-center">
              <button type="button" id="modal_submit">
                <i className="fa fa-phone" aria-hidden="true"></i>&nbsp;Book now
              </button>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <button
            onClick={handleClose}
            type="button"
            className="btn btn-primary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default Navigation;
