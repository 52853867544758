import React from "react";
import { NavLink } from "react-router-dom";
function Footer() {
    return (
        <footer id="footer">

            <div className="footer-top">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 footer-newsletter">
                            <h4>Bridge<span>Insure</span></h4>
                            <p>
                                With BridgeInsure, you don't just compare insurance. You understand it. Narrow down choices. Avoid pitfalls and make better decisions.
                            </p>
                            <div className="social-links text-md-right pt-md-0">
                                <a href="https://www.facebook.com/BridgeInsure" target="_blank" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="https://www.linkedin.com/company/bridgeinsure-global-insurance-broker-pvt-ltd/" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>

                        <div className="col-lg-1 footer-newsletter">
                        </div>



                        <div className="col-lg-5 footer-contact">
                            <h3>Keep in <span>Touch</span></h3>
                            <p style={{marginBottom:4}}>

                            </p>
                            <div className="fbw-btn">
                                { /*<a className="f-Book-call" href="tel:8109390566"><i className="fa fa-calendar-o" aria-hidden="true"></i>&nbsp; Book a free call</a>*/}
                                <a className="f-whatsApp" href="https://api.whatsapp.com/send/?phone=918109390566&amp;text&amp;app_absent=0" target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i>&nbsp; WhatsApp</a>
                            </div>
                            <a href="mailto:contact@bridgeinsure.com">contact@bridgeinsure.com</a> | <a href="tel:8109390566">8109390566</a>
                        </div>

                        <div className="col-lg-3 footer-links">
                            <h4>Useful <span>Links</span></h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                                <li><i className="bx bx-chevron-right"></i><NavLink to="/terms-of-use">Terms of Use</NavLink></li>
                                <li><i className="bx bx-chevron-right"></i><NavLink to="/grievance-redressal-policy">Grievance Redressal Policy</NavLink></li>
                            </ul>
                        </div>



                    </div>
                </div>
            </div>

            <div className="container d-md-flex crMain">

                <div className="me-md-auto text-center text-md-start">
                    <div className="copyright">
                        &copy; Copyright <strong><span>BridgeInsure</span></strong>. All Rights Reserved
                    </div>
                </div>

                <div className="credits text-md-right">
                    Designed by
                    <a href="https://www.softomatic.tech/" target="_blank"><img src="assets/img/softomatic.svg" /></a>
                </div>

            </div>
        </footer>
    );
}
export default Footer;