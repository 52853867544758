import { useState, React, useEffect, useRef } from "react";
import { generateOTP, verifyOTP } from "../../api";
import UploadPolicyForm from "./UploadPolicyForm";

export default function GapAnalysis() {
  const [wrongFile, setWrongFile] = useState(false);
  const [wrongPolicyFile, setWrongPolicyFile] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobileNo, setMobileNo] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [OTP, setOTP] = useState("");
  const [isOTP, setIsOTP] = useState(false);
  const [OTPId, setOTPId] = useState("");
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    mobileNo: "",
    farreport: "",
    policyreport: "",
  });
  const nameinput = useRef(null);
  const emailinput = useRef(null);
  const mobilenoinput = useRef(null);

  useEffect(() => {
    if (name !== null) {
      if (!name.match("^[A-Za-z ]*$")) {
        setValidationErrors({ ...validationErrors, name: "Invalid Name" });
      } else {
        setValidationErrors({ ...validationErrors, name: "" });
      }
    }
  }, [name]);

  useEffect(() => {
    if (firstName !== null) {
      if (!firstName.match("^[A-Za-z ]*$")) {
        setValidationErrors({ ...validationErrors, firstName: "Invalid Name" });
      } else {
        setValidationErrors({ ...validationErrors, firstName: "" });
      }
    }
  }, [firstName]);

  useEffect(() => {
    if (lastName !== null) {
      if (!lastName.match("^[A-Za-z ]*$")) {
        setValidationErrors({ ...validationErrors, lastName: "Invalid Name" });
      } else {
        setValidationErrors({ ...validationErrors, lastName: "" });
      }
    }
  }, [lastName]);

  useEffect(() => {
    if (email != null) {
      if (!email.match("^[a-z0-9]+[.a-z0-9]*@[a-z]+.[a-z]{2,9}$")) {
        setValidationErrors({ ...validationErrors, email: "Invalid Email" });
      } else {
        setValidationErrors({ ...validationErrors, email: "" });
      }
    }
  }, [email]);

  useEffect(() => {
    if (mobileNo != null) {
      if (!mobileNo.match("^[0-9]{10}$")) {
        setValidationErrors({
          ...validationErrors,
          mobileNo: "Invalid Mobile No",
        });
      } else {
        setValidationErrors({ ...validationErrors, mobileNo: "" });
      }
    }
  }, [mobileNo]);

  const otpGenerate = () => {
    generateOTP(mobileNo, "initial", firstName).then((res)=> {     
      setOTPId(res.id);
    } );
  };

  const OTPVerify = (otp, id) => {
    console.log(id);
    verifyOTP(otp, id).then((res) => {
      if (res.success) {
        setIsOTPVerified(true);
      } else {
        setIsOTPVerified(false);
        setValidationErrors({
          ...validationErrors,
          otp: res.message,
        });
      }
    });
  };

  return (
    <main
      className="container"
      style={{ paddingTop: "6%", paddingBottom: "8%" }}
    >
      <section className="w-50" style={{ margin: "0 auto" }}>
        {isOTPVerified ? (
          <UploadPolicyForm
            wrongPolicyFile={wrongPolicyFile}
            name={`${firstName} ${lastName}`}
            company={name}
            number={mobileNo}
            email={email}
            id={OTPId}
          />
        ) : isOTP ? (
          <>
            <div className="d-flex justify-content-between align-items-center mb-5">
              <p className="m-0">OTP: </p>
              <div style={styles.fieldCol}>
                <input
                  name="otp"
                  className="form-control"
                  placeholder="Please enter OTP"
                  disabled={disableFields}
                  value={OTP}
                  onChange={(e) => setOTP(e.target.value)}
                />
                <span style={styles.error}>{validationErrors.otp}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                style={{ width: "60%" }}
                type="button"
                className="btn btn-primary"
                disabled={OTP === ""}
                onClick={() => OTPVerify(OTP, OTPId)}
              >
                Submit
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center mb-3 ">
              <p className="m-0">Company Name: </p>
              <div style={styles.fieldCol}>
                <input
                  name="name"
                  className="form-control"
                  ref={nameinput}
                  placeholder="Company Name"
                  disabled={disableFields}
                  onChange={(e) => setName(e.target.value)}
                />
                <span style={styles.error}>{validationErrors.name}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0">First Name: </p>
              <div style={styles.fieldCol}>
                <input
                  name="firstname"
                  className="form-control"
                  ref={nameinput}
                  placeholder="First Name"
                  disabled={disableFields}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <span style={styles.error}>{validationErrors.firstName}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0">Last Name: </p>
              <div style={styles.fieldCol}>
                <input
                  name="firstname"
                  className="form-control"
                  ref={nameinput}
                  placeholder="Last Name"
                  disabled={disableFields}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <span style={styles.error}>{validationErrors.lastName}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className="m-0">Contact Person: </p>
              <div style={styles.fieldCol}>
                <input
                  name="mobileno"
                  className="form-control"
                  ref={mobilenoinput}
                  placeholder="10 Digit Phone Number"
                  disabled={disableFields}
                  onChange={(e) => setMobileNo(e.target.value)}
                />
                <span style={styles.error}>{validationErrors.mobileNo}</span>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-5">
              <p className="m-0">Email: </p>
              <div style={styles.fieldCol}>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  ref={emailinput}
                  placeholder="Email Id"
                  disabled={disableFields}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <span style={styles.error}>{validationErrors.email}</span>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                style={{ width: "60%" }}
                type="button"
                className="btn btn-primary"
                disabled={
                  wrongFile ||
                  name === "" ||
                  firstName === "" ||
                  lastName === "" ||
                  email === "" ||
                  mobileNo === "" ||
                  name === null ||
                  firstName === null ||
                  lastName === null ||
                  email === null ||
                  mobileNo === null ||
                  validationErrors.name !== "" ||
                  validationErrors.firstName !== "" ||
                  validationErrors.lastName !== "" ||
                  validationErrors.email !== "" ||
                  validationErrors.mobileNo !== ""
                }
                onClick={() => {
                  setIsOTP(true);
                  otpGenerate();
                }}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </section>
    </main>
    // <main id="main" style={{ paddingTop: "6%", paddingBottom: "8%" }}>
    //   <section id="contact" className="contact p-5" style={{ width: "50%" }}>
    //     <div>
    //       <div className="d-flex flex-row align-items-center ">
    //         <br />
    //         <p className="h-100">Company Name</p>
    //         <div className="col-lg-12" style={styles.fieldCol}>
    //           <input
    //             name="name"
    //             className="form-control"
    //             ref={nameinput}
    //             placeholder="Company Name"
    //             disabled={disableFields}
    //             onChange={(e) => setName(e.target.value)}
    //           />
    //           <span style={styles.error}>{validationErrors.name}</span>
    //         </div>
    //       </div>
    //       <br />
    //       <div
    //         className="d-flex flex-row align-items-center"
    //         style={{ width: "100%" }}
    //       >
    //         <p>Contact Person</p>
    //         <div className="" style={styles.fieldCol}>
    //           <input
    //             name="mobileno"
    //             className="form-control"
    //             style={{ width: "fit-content" }}
    //             ref={mobilenoinput}
    //             placeholder="First Name"
    //             disabled={disableFields}
    //             onChange={(e) => setMobileNo(e.target.value)}
    //           />
    //           <span style={styles.error}>{validationErrors.mobileNo}</span>
    //         </div>
    //         <div className="" style={styles.fieldCol}>
    //           <input
    //             name="mobileno"
    //             className="form-control"
    //             ref={mobilenoinput}
    //             style={{ width: "fit-content" }}
    //             placeholder="Last Name"
    //             disabled={disableFields}
    //             onChange={(e) => setMobileNo(e.target.value)}
    //           />
    //           <span style={styles.error}>{validationErrors.mobileNo}</span>
    //         </div>
    //       </div>
    //       <div className="d-flex flex-row justify-content-center align-items-center">
    //         <p>Contact Number</p>
    //         <div className="col-lg-12" style={styles.fieldCol}>
    //           <input
    //             name="mobileno"
    //             className="form-control"
    //             ref={mobilenoinput}
    //             placeholder="10 digit Phone Number"
    //             disabled={disableFields}
    //             onChange={(e) => setMobileNo(e.target.value)}
    //           />
    //           <span style={styles.error}>{validationErrors.mobileNo}</span>
    //         </div>
    //       </div>
    //       <br />
    //       <div className="col-lg-12" style={styles.fieldCol}>
    //         <input
    //           type="email"
    //           name="email"
    //           className="form-control"
    //           ref={emailinput}
    //           placeholder="Email Id"
    //           disabled={disableFields}
    //           onChange={(e) => setEmail(e.target.value)}
    //         />
    //         <span style={styles.error}>{validationErrors.email}</span>
    //       </div>
    //       <br />

    //       {/* <div className="row">
    //             <div className="col-lg-12" style={styles.fieldCol}>
    //               <input
    //                 type="file"
    //                 name="pdffile"
    //                 className="form-control"
    //                 ref={fileinput}
    //                 accept="application/pdf"
    //                 placeholder="choose a pdf file here"
    //                 disabled={disableFields}
    //                 onChange={(e) => fileSelected(e)}
    //               />
    //               {wrongFile && (
    //                 <span className="text-danger">
    //                   Please select PDF file only
    //                 </span>
    //               )}
    //             </div>
    //             <div className="col-lg-12" style={styles.fieldCol}>
    //               <label>Upload a pdf file with FAR Report</label>
    //             </div>
    //           </div> */}
    //       <br />
    //       <br />
    //       {/* {!submitWithoutPolicy && (
    //             <>
    //               <br />
    //               <div className="row">
    //                 <div className="col-lg-12" style={styles.fieldCol}>
    //                   <input
    //                     type="file"
    //                     name="policyfile[]"
    //                     className="form-control"
    //                     ref={policyfileinput}
    //                     accept="application/pdf"
    //                     placeholder="choose a pdf file here"
    //                     disabled={disableFields}
    //                     onChange={(e) => policyfileSelected(e)}
    //                     multiple="multiple"
    //                   />
    //                   {wrongPolicyFile && (
    //                     <span className="text-danger">
    //                       Please select PDF file only
    //                     </span>
    //                   )}
    //                 </div>
    //               </div>
    //             </>
    //           )} */}
    //       <br />
    //       <div className="row">
    //         <div
    //           className="col-lg-11 text-center"
    //           style={{ paddingLeft: "0%" }}
    //         >
    //           <button
    //             style={{ width: "90%" }}
    //             type="button"
    //             className="btn btn-primary"
    //             disabled={
    //               wrongFile ||
    //               name === "" ||
    //               email === "" ||
    //               mobileNo === "" ||
    //               name === null ||
    //               email === null ||
    //               mobileNo === null ||
    //               pdffile === "" ||
    //               pdffile === null ||
    //               (!submitWithoutPolicy &&
    //                 (policyfile === "" ||
    //                   policyfile === null ||
    //                   policyType === "")) ||
    //               inputVal === "" ||
    //               inputVal === null ||
    //               disableFields ||
    //               validationErrors.name !== "" ||
    //               validationErrors.email !== "" ||
    //               validationErrors.mobileNo !== ""
    //             }
    //             onClick={() => startGapAnalysis()}
    //           >
    //             Start Analysis
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </main>
  );
}

const styles = {
  head: {
    marginTop: "10px",
  },
  historyTitle: {
    // border:'1px solid lightgrey',
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "#16243d",
    color: "white",
    fontWeight: "bold",
  },
  historyrows: {
    margin: "10px 0px 15px 0px",
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: "#f9f9f9",
    border: "1px solid #d0d0d0",
  },
  borderbottom: {
    // borderBottom:'1px solid #000000',
    // marginLeft:'-40px',
    // marginRight:'-40px',
    // opacity:0.5
  },
  fieldCol: {
    paddingLeft: "0px",
    paddingRight: "0px",
    marginLeft: "0px",
    marginRight: "0px",
    width: "350px",
    overflow: "hidden",
    fontSize: "12px",
  },
  error: {
    color: "red",
    fontSize: "12px",
  },
  select: {
    width: "100%",
    fontSize: "15px",
  },
  btn: {
    width: "100%!important",
  },
  th: {
    fontSize: "15px",
  },
  td: {
    fontSize: "15px",
  },
  formControl: {
    fontSize: "15px",
  },
};
