import { React, useState } from "react";
import { Helmet } from 'react-helmet';

function GrievanceRedressalPolicy() {

    return (
        <>
            <Helmet>
                <title>Welcome - Grievance Redressal Policy</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>


            <main id="main" style={{ paddingTop: 70 }}>
                <section id="" className="inpageMain">
                    <div className="container">
                        <div className="inpageHeadingTital">
                            <h4>Grievance Redressal policy</h4>
                            <hr />
                        </div>
                        <div className="row inpageContentTag">
                            <div className="col-sm-12">
                                <h5>Grievance Redressal </h5>
                                <p>
                                    BridgeInsure Global Insurance Broker Private Limited (hereinafter referred to as “Bridgeinsure”), is concerned about the complaint/grievance of the users and/or clients
                                </p>
                                <p>
                                    This grievance redressal aims at minimizing the instances of customer complaints and grievances through proper service delivery and review mechanism to ensure prompt redressal of customer complaints and grievances. The review mechanism is aimed at helping
                                    in identification of shortcomings in service delivery, if any, and compliance with the stipulations of IRDAI Regulations.
                                </p>
                                <h5>Grievance Redressal</h5>
                                <p>
                                    To ensure that Bridgeinsure’s grievance redressal mechanism is more meaningful and effective, a system/ process has been designed. Such system would ensure that the redressal provided is just and fair and is permissible within the given framework of the
                                    rules and regulations. This grievance redressal would be made available at all regional offices/branch offices of Bridgeinsure. The concerned employees in the respective branch offices shall be made aware about the complaints/
                                    grievance handling process.
                                </p>
                                <h5>Process</h5>
                                <p>The process followed for addressing queries shall be:</p>
                                <ul>
                                    <li>
                                        For any grievance, please contact us with the details by sending an email to the Grievance team, on ‘<a href="mailto:complaints@bridgeinsure.com">Complaints@bridgeinsure.com</a>’ or to our Grievance Officer Ms. Purvika
                                        Mishra on <a href="mailto:purvika.mishra@bridgeinsure.com">Purvika.mishra@bridgeinsure.com</a>
                                    </li>
                                    <li>You can also write to <b>BridgeInsure Global Insurance Broker Private Limited, Office No. 105-106, Bagpat Road, Transport Nagar Meerut, UP 250002 or reach us on +918109390566.</b></li>
                                    <li>The grievance will be acknowledged within 2 working days of its receipt.</li>
                                    <li>Bridgeinsure shall exercise all efforts to address it, including intimation to the respective Insurance company for a quick redressal.</li>
                                    <li>If the decision/resolution provided by the Grievance Officer is not acceptable, please approach the Principal Officer of the Company at <a href="mailto:dwipa.shah@bridgeinsure.com">dwipa.shah@bridgeinsure.com</a></li>
                                    <li>If the decision/resolution provided by the Principal Officer is not acceptable, the grievance may be further escalated as per the process specified by Insurance Regulatory Development Authority of India (IRDAI) in the below
                                        mentioned link: </li>
                                </ul>
                                <p><a href="https://www.policyholder.gov.in/report.aspx" target="_blank">https://www.policyholder.gov.in/report.aspx</a></p>
                                <p>
                                    In case of no reply from the Complainant, within 2 weeks from the date any clarification was provided, the Comp any shall treat the Complaint as Closed.
                                </p>
                                <h5>Disclaimer</h5>
                                <p>
                                    <a href="https://www.bridgeinsure.com">https://www.bridgeinsure.com</a> is an Insurance domain owned by BridgeInsure Global Insurance Broker Private Limited.
                                </p>
                                <p>
                                    <b>Registered Office</b>: Office No. 105-106, Bagpat Road, Transport Nagar Meerut, UP 250002. CIN No.: U66000UP2021PTC150927. IRDA License No: 836, Direct Broker (Life & General), valid up to 19/07/2025 (renews every 3 years).
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>

    );
}
export default GrievanceRedressalPolicy;