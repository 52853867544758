import { React, useState } from "react";
import { Helmet } from 'react-helmet';
import { letUsContactYou } from "../api";
function Contact() {

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [companySize, setCompanySize] = useState(null);
    const [companyRevenue, setCompanyRevenue] = useState(null);
    const [locationAddress, setLocationAddress] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if(isValid){
            console.log(isValid);
            console.log(name);
            console.log(email);
            console.log(phone);
            console.log(companyName);
            console.log(companySize);
            console.log(companyRevenue);
            console.log(locationAddress);
            setLoading(true);
            let result = await letUsContactYou({name:name,phone:phone,email:email,companyName:companyName, companySize:companySize, companyRevenue:companyRevenue, locationAddress:locationAddress});
            if(result){
                console.log(result);
                if(result=='success'){
                    setSuccess(true);
                }
                else{
                    setError(true);
                }
                // setResponseMsg(result.msg);
            }            
        }
        
    };

    const validateForm=()=> {
        // $('.text-danger').html('');
        // var name = $('#name').val();
        // var phone_no = $('#phone_no').val();
        // var email = $('#email').val();
        // var industry_type = $('#industry_type').val();
        // var company_name = $('#company_name').val();
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var valid = true;
        if (name === "") {
            // $('.name_err').html('Name required');
            valid = false;
        }
        if (phoneno.test(phone) === false) {
            // $('.phone_no_err').html('10 digit phone no required');
            valid = false;
        }
        if (mailformat.test(email) === false) {
            // $('.email_err').html('Valid email id required');
            valid = false;
        }
        if (companySize === "") {
            // $('.company_size_err').html('Company size required');
            valid = false;
        }
        if (companyName === "") {
            // $('.company_name_err').html('Company name required');
            valid = false;
        }
        if (companyRevenue === "") {
            // $('.company_revenue_err').html('Company revenue required');
            valid = false;
        }
        if (locationAddress === "") {
            // $('.location_address_err').html('Location address required');
            valid = false;
        }
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Welcome - Contact</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>
            <main id="main" style={{ paddingTop: '8%' }}>
                <section id="contact" className="contact">
                    <div className="container">

                        <div className="row justify-content-center" data-aos="fade-up">

                            <div className="col-lg-4 contact-page">
                                <div className="info-wrap">
                                    <div className="row">

                                        <div className="info mt-4 mt-lg-0">
                                            <i className="bi bi-envelope"></i>
                                            <h4>Email:</h4>
                                            <a href="mailto:contact@bridgeinsure.com">
                                                <p>contact@bridgeinsure.com</p>
                                            </a>
                                        </div>

                                        <div className="info mt-4 mt-lg-0">
                                            <i className="bi bi-phone"></i>
                                            <h4>Call:</h4>
                                            <p>8109390566</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8">
                                <form style={{ padding: 20 }} onSubmit={(e)=>handleSubmit(e)} className="php-email-form">
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)} required />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input type="number" className="form-control" name="number" id="number" placeholder="Phone Number" onChange={(e)=>setPhone(e.target.value)} required />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input type="email" className="form-control" name="Company Email" id="Company Email" placeholder="Company Email" onChange={(e)=>setEmail(e.target.value)} required />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4 form-group">
                                            <input type="text" name="company name" className="form-control" id="company name" placeholder="Company Name" onChange={(e)=>setCompanyName(e.target.value)} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input type="text" name="Company Size" className="form-control" id="Company Size " placeholder="Company Size" onChange={(e)=>setCompanySize(e.target.value)} />
                                        </div>
                                        <div className="col-md-4 form-group">
                                            <input type="text" name="Company Revenue" className="form-control" id="Company Revenue" placeholder="Company Revenue" onChange={(e)=>setCompanyRevenue(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea className="form-control" name="Location address" rows="5" placeholder="Location address" onChange={(e)=>setLocationAddress(e.target.value)} required></textarea>
                                    </div>
                                    <div className="">
                                        {loading && <div className="loading alert">Please wait....</div>}
                                        {error && <div className="error-message alert">Message can't be sent. - this will change to - Oops Seem like there is an issue, Please check back soon !</div>}
                                        {success && <div className="sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>}
                                    </div>
                                    <div className="text-center"><button type="submit">Submit&nbsp; <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button></div>
                                </form>
                            </div>

                        </div>


                    </div>
                </section>

            </main></>
    );
}
export default Contact;