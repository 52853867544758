const policyDocumentParserApi =
  "https://rzzdv2yugzxxo2hvydz2ynuqvu0zobjk.lambda-url.ap-south-1.on.aws/";
const policyDocumentParserSBIApi =
  "https://yxgwyfx5su226whpbipar6zfge0rshmh.lambda-url.ap-south-1.on.aws/";
const policyDocumentParserTataApi =
  "https://gg5sw76obzmet5lljhvf5sojim0kaeno.lambda-url.ap-south-1.on.aws/";
const policyDocumentParserRelianceApi =
  "https://yuowsu537x6a2und7wx2w34bqe0cxxms.lambda-url.ap-south-1.on.aws/";
const policyDocumentParserBajajApi =
  "https://5vw3krspinzznq7zw5vej4sux40hhcdj.lambda-url.ap-south-1.on.aws/";
const policyDocumentParserNewIndiaApi =
  "https://mxf4z55cgzxmu3esefva2ja2ea0ssgok.lambda-url.ap-south-1.on.aws/";
export async function letUsContactYou(data) {
  const response = await fetch(
    `https://softomatic.tech/andfintech/backendMicroService/save_contact`,
    {
      method: "POST",
      body: data,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  return await response.json();
}

export const generateOTP = async (mobileNo, message, name) => {
  const response = await fetch(
    `https://softomatic.tech/andfintech/backendMicroService/generate_otp`,
    {
      method: "POST",
      body: JSON.stringify({ mobileNo, message, name }),
      headers: { "content-type": "application/json" },
    }
  );
  // console.log(await response.text());
  return await response.json();
};

export const verifyOTP = async (otp, id) => {
  const data = { otp, id };
  const response = await fetch(
    `https://softomatic.tech/andfintech/backendMicroService/verify_otp`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "content-type": "application/json" },
    }
  );
  return await response.json();
};

export const getFiles = async (mobileNo) => {
  const data = { mobileNo };
  const response = await fetch(
    `https://softomatic.tech/andfintech/backendMicroService/get_files`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "content-type": "application/json" },
    }
  );
  return await response.json();
};

export const gapAnalysis = async (
  name,
  company,
  email,
  number,
  interests,
  requirements,
  files
) => {
  interests = interests.toString();
  const data = new FormData();
  data.append("name", name);
  data.append("company", company);
  data.append("email", email);
  data.append("number", number);
  data.append("interests", interests);
  data.append("requirements", requirements);
  files.map((item, index) => {
    data.append(`file${index + 1}`, item);
  });
  data.append("totalFiles", files.length);
  try {
    const response = await fetch(
      `https://softomatic.tech/andfintech/backendMicroService/gap_analysis`,
      {
        method: "POST",
        body: data,
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const uploadToGCS = async (name, id, files) => {
  const data = new FormData();
  let tempName = "";
  files.map((item, index) => {
    data.append(`file${index + 1}`, item);
    tempName = `${name}_${index}`;
  });
  data.append("name", tempName);
  data.append("totalFiles", files.length);

  await fetch(
    `https://softomatic.tech/andfintech/backendMicroService/upload_to_GCS`,
    {
      method: "POST",
      body: data,
    }
  );
};

export async function saveLead(data) {
  const response = await fetch(
    `https://softomatic.tech/andfintech/backendMicroService/save_leads`,
    {
      method: "POST",
      body: data,
      headers: { "content-type": "application/json" },
    }
  );
  return await response.json();
}

export function uploadToS3(formData, filename, filetype, foldername) {
  return fetch(
    "https://uaqbkxkmea3tlc6vrnc7xm3p7i0oypwj.lambda-url.ap-south-1.on.aws/",
    {
      method: "POST",
      body: formData,
      headers: {
        "content-type": "multipart/form-data",
        filename: filename,
        filetype: filetype,
        foldername: foldername,
      },
    }
  );
}

export function financialDocumentParser(formData) {
  console.log(formData);
  return fetch(
    "https://l4awxapbutmfuw3twy732rcvpa0paghz.lambda-url.ap-south-1.on.aws/",
    {
      method: "POST",
      body: formData,
      headers: { "content-type": "application/json" },
    }
  );
}

export function policyDocumentParser(formData, policytype) {
  var endpoint = policyDocumentParserApi;
  if (policytype == "sbi") endpoint = policyDocumentParserSBIApi;
  if (policytype == "bajaj") endpoint = policyDocumentParserBajajApi;
  if (policytype == "tata") endpoint = policyDocumentParserTataApi;
  if (policytype == "reliance") endpoint = policyDocumentParserRelianceApi;
  if (policytype == "newindia") endpoint = policyDocumentParserNewIndiaApi;
  console.log(formData);
  return fetch(endpoint, {
    method: "POST",
    body: formData,
    headers: { "content-type": "application/json" },
  });
}

export function insuranceCostCalculator(formData) {
  console.log(formData);
  return fetch(
    "https://4dn4e3bppk.execute-api.ap-south-1.amazonaws.com/default/insuranceCostCalculator",
    {
      method: "POST",
      body: formData,
      headers: { "content-type": "application/json" },
    }
  );
}
