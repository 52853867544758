import React, { useState } from "react";
import more from "./more.png";
import { gapAnalysis } from "../../api";
import { useNavigate } from "react-router-dom";

const UploadPolicyForm = ({
  wrongPolicyFile,
  name,
  company,
  number,
  email,
  id,
}) => {
  const navigate = useNavigate();
  const [totalFileInputs, setTotalFileInputs] = useState(1);
  const [files, setFiles] = useState([]);
  const [productsOFInterest, setProductsOFInterest] = useState([]);
  const [isTerms, setIsTerms] = useState(false);
  const [requirements, setRequirements] = useState("");

  console.log(name);

  const checkboxes = [
    {
      id: 1,
      value: "Commercial",
    },
    {
      id: 2,
      value: "Personel",
    },
    {
      id: 3,
      value: "Professional",
    },
  ];

  return (
    <div>
      <p style={{ textAlign: "center" }} className="mb-5">
        Upload Policy Document
      </p>
      <div className="d-flex align-items-start justify-content-evenly">
        <div
          className="d-flex align-items-center mt-1"
          style={{ cursor: "pointer" }}
          onClick={() => setTotalFileInputs(totalFileInputs + 1)}
        >
          <button
            type="button"
            style={{
              background: "none",
              outline: "none",
              border: "none",
            }}
          >
            Add More
          </button>
          <img src={more} alt="more" width={15} height={15} />
        </div>
        <div>
          {Array(totalFileInputs)
            .fill()
            .map((item, index) => {
              return (
                <div
                  key={index}
                  className={`d-flex justify-content-between align-items-center ${
                    index !== totalFileInputs - 1 && "mb-3"
                  }`}
                >
                  <div>
                    <input
                      type="file"
                      name="policyfile"
                      className="form-control"
                      accept="application/pdf"
                      placeholder="choose a pdf file here"
                      onChange={({ target: { files } }) => {
                        setFiles((prev) => {
                          let temp = [...prev];
                          temp.push(files[0]);
                          return temp;
                        });
                      }}
                    />
                    {wrongPolicyFile && (
                      <span className="text-danger">
                        Please select PDF file only
                      </span>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <p style={{ textAlign: "center" }} className="mt-5 mb-4">
        Select All The Products Of Interest
      </p>
      <div className="d-flex flex-column align-items-center justify-content-center">
        {checkboxes.map((item) => {
          return (
            <div key={item.id} className="d-flex align-items-center w-25 mb-1">
              <div className="form-check">
                <input
                  type="checkbox"
                  style={{ marginRight: "2rem" }}
                  className="form-check-input"
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (productsOFInterest.includes(item.value)) return;
                      let temp = [...productsOFInterest];
                      temp.push(item.value);
                      setProductsOFInterest(temp);
                    }
                  }}
                />
              </div>
              <p style={{ margin: 0, fontSize: "1.2rem" }}>{item.value}</p>
            </div>
          );
        })}
        <textarea
          rows={3}
          cols={40}
          value={requirements}
          onChange={(e) => setRequirements(e.target.value)}
          style={{
            textAlign: "center",
            padding: "0.5rem",
            resize: "none",
            marginTop: "1rem",
            borderRadius: "5px",
          }}
          placeholder="Please describe your requirements
or any specifics you are looking for"
        />
        <div className="form-check my-3">
          <input
            type="checkbox"
            className="form-check-input"
            style={{ marginRight: "2rem" }}
            id="flexRadioDefault1"
            onChange={(e) => setIsTerms(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            I accept the Privacy Terms
          </label>
        </div>
        <button
          disabled={!isTerms}
          type="button"
          className="btn btn-primary"
          onClick={() => {
            gapAnalysis(
              name,
              company,
              email,
              number,
              productsOFInterest,
              requirements,
              files
            );
            //uploadToGCS(name, id, files);
            //navigate("/");
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default UploadPolicyForm;
