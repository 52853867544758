import { React, useState } from "react";
import { Helmet } from 'react-helmet';

function PrivacyPolicy() {

    return (
        <>
            <Helmet>
                <title>Welcome - Privacy Policy</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>


            <main id="main" style={{ paddingTop: 70 }}>
                <section id="" className="inpageMain">
                    <div className="container">
                        <div className="inpageHeadingTital">
                            <h4>Privacy Policy</h4>
                            <hr />
                        </div>
                        <div className="row inpageContentTag">
                            <div className="col-sm-12">
                                <p>
                                    This Privacy Policy informs you of the manner in which BridgeInsure Global Insurance Broker Private Limited (hereinafter referred to as “Bridgeinsure”) collects, uses, maintains and discloses Personal Information collected from users (each, a “User”)
                                    of the site
                                    <a href="https://www.bridgeinsure.com"> https://www.bridgeinsure.com</a> (hereinafter referred to as “Website”).
                                </p>
                                <h5>
                                    Collection of personally identifiable and other Information
                                </h5>
                                <p>
                                    When you use our Website, we collect and store your Personal Information as provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience.
                                </p>
                                <p>
                                    In general, you can browse the Website without telling us who you are or revealing any personal information about yourself. Once you give us your personal information, you are not anonymous to us. We may collect Personal Information from Users in a variety
                                    of ways, including, but not limited to, when Users visit our site, register on the site, place an order, subscribe to the newsletter, respond to a survey, fill out a form, and in connection with other activities, services,
                                    features or resources we make available on our Website. Personal Information would include name, email address, mailing address, or phone number. If User transacts on the Website, we may collect additional Personal Information
                                    such as billing address, a credit / debit card number, expiration date, and/ or other payment instrument details and tracking information from cheques or money orders.
                                </p>
                                <p>
                                    We may collect non-personally identifiable information about Users such browser name, operating system, name of ISP, etc., with the goal of improving our Service.
                                </p>
                                <h5>Cookies</h5>
                                <p>
                                    A “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back from that browser. Cookies are useful for enabling the browser to remember information specific to a given user.
                                </p>
                                <p>
                                    We place both permanent and temporary cookies in your computer’s hard drive. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent.
                                </p>
                                <h5>Use of your information</h5>
                                <p>
                                    We may use your Personal Information for the following purposes:
                                </p>
                                <ul>
                                    <li>To personalize user experience</li>
                                    <li>To improve our Website, products and services we offer</li>
                                    <li>To issue your policy</li>
                                    <li>To process payments</li>
                                    <li>To improve customer service</li>
                                    <li>To send you information and updates pertaining to your order</li>
                                    <li>To send periodic emails in the form of a newsletter, updates about our products or other promotions. Email unsubscribe instructions will be included at the bottom of each email or User may contact our support team</li>
                                    <li>To contact you via email or phone on your contact details provided to offer you information and services pertaining to the product you have opted for. You authorise us to call you for the mentioned purpose for a period of up
                                        to 30 days irrespective of whether you are registered with the NDNC registry.</li>
                                </ul>
                                <h5>Protection of your information</h5>
                                <p>
                                    We adopt appropriate data collection, storage and processing practices and security measures to protect against unauthorized access, alteration, disclosure or destruction of your Personal Information and data stored on our Website.
                                </p>
                                <h5>Sharing your personal information</h5>
                                <p>
                                    We may share personal information with our other corporate entities and affiliates to help us operate our business and the Website or administer activities such as detection of identity theft, fraud and other potentially illegal acts, to facilitate joint
                                    or co-branded services that you request where such services are provided by more than one corporate entity. We may share your information with these third parties for those limited purposes provided that you have given us your
                                    permission.
                                </p>
                                <p>
                                    We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process.
                                </p>
                                <p>
                                    We may use third-party advertising companies to serve ads when you visit our Website. These companies may use information about your visits to this and other websites in order to provide advertisements about goods and services of interest to you. We do
                                    NOT share personally identifiable information with such third party advertising companies.
                                </p>
                                <h4>WE DO NOT SELL YOUR PERSONAL INFORMATION WITH ANY THIRD PARTY FOR ANY PURPOSE WHATSOEVER.</h4>
                                <h5>Links to third party websites</h5>
                                <p>
                                    Users may find advertising or other content on our Website that link to the sites and services of our partners and other third parties. We do not control the content or links that appear on these sites and are not responsible for the practices employed
                                    by websites linked to or from our Website. Browsing and interaction on any other website, including websites which have a link to our Website, is subject to that website’s own terms and policies.
                                </p>
                                <h5>Your consent</h5>
                                <p>
                                    By using the Website and/ or by providing your information, you consent to the collection and use of the information you disclose on the Website in accordance with this Privacy Policy, including but not limited to your consent for sharing your information
                                    as per this Privacy Policy.
                                </p>
                                <p>
                                    If we decide to change our Privacy Policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it. We encourage you to frequently check for any change
                                    in these terms.
                                </p>
                                <p>
                                    “I hereby authorize, and give consent to BridgeInsure Global Insurance Broker Private to send me, either through itself or through any third party service provider, from time to time various information / alerts / SMS/ other messages or calls or commercial
                                    communication, and other services on the aforesaid listed telephone numbers, whether these numbers are registered with National Do Not Call Registry/ listed in the National Customer Preference Register or not. I also confirm
                                    that by sending any such messages / calls I will not hold Company or its authorised third party service provider liable/ institute complaint against under the Telecom Commercial Communications Customer Preference (TRAI) Regulations,
                                    2010 or such other applicable regulations including any amendment thereof, as maybe applicable from time to time.”
                                </p>
                                <h5>Complaints & Grievance</h5>
                                <p>
                                    For any complaints, services related issues or policy, claim related queries/ customer can reach us on – <a href="tel:8109390566">8109390566</a> Or address mail to <a href="mailto:contact@bridgeinsure.com">contact@bridgeinsure.com</a>
                                </p>
                                <p>
                                    For escalations customer can write to us at <a href="mailto:rahul.gupta@bridgeinsure.com">rahul.gupta@bridgeinsure.com</a> or call us at <a href="tel:+91-9971491205">+91-9971491205</a>
                                </p>
                                <p>
                                    For details on our grievance policy visit Grievance Redressal policy.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>

    );
}
export default PrivacyPolicy;