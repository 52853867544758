import { React, useState } from "react";
import { Helmet } from 'react-helmet';
import { letUsContactYou } from "../api";
function CommercialInsurance() {

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [industryType, setIndustryType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if(isValid){
            console.log(isValid);
            console.log(name);
            console.log(email);
            console.log(phone);
            console.log(companyName);
            console.log(industryType);
            setLoading(true);
            let result = await letUsContactYou({name:name,phone:phone,email:email,companyName:companyName, industryType:industryType});
            if(result){
                console.log(result);
                if(result=='success'){
                    setSuccess(true);
                }
                else{
                    setError(true);
                }
                // setResponseMsg(result.msg);
            }            
        }
        
    };

    const validateForm=()=> {
        // $('.text-danger').html('');
        // var name = $('#name').val();
        // var phone_no = $('#phone_no').val();
        // var email = $('#email').val();
        // var industry_type = $('#industry_type').val();
        // var company_name = $('#company_name').val();
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var valid = true;
        if (name === "") {
            // $('.name_err').html('Name required');
            valid = false;
        }
        if (phoneno.test(phone) === false) {
            // $('.phone_no_err').html('10 digit phone no required');
            valid = false;
        }
        if (mailformat.test(email) === false) {
            // $('.email_err').html('Valid email id required');
            valid = false;
        }
        if (industryType === "") {
            // $('.industry_type_err').html('Industry type required');
            valid = false;
        }
        if (companyName === "") {
            // $('.company_name_err').html('Company name required');
            valid = false;
        }
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Welcome - Commercial Insurance</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>
        
        
        <main id="main" style={{ paddingTop: 90 }}>
            <section id="" className="inpageMain">
                <div className="container">
                    <div className="row">
                        <div className="modal_sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>
                        <div className="col-lg-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-Fire" role="tabpanel" aria-labelledby="nav-Fire-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Fire Insurance</h3>
                                            <h6>
                                                Property insurance policies protect your assets like buildings, plant and machinery, stocks, office equipment against losses caused by various man made and natural perils. These policies cover the cost to replace or repair the property:
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/fire-insurance.png" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Engineering" role="tabpanel" aria-labelledby="nav-Engineering-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Engineering Insurance</h3>
                                            <h6>
                                                These policies protect your assets under construction against losses caused by fire, floods, storms, earthquake, terrorism, theft, accidental damage and breakdown. These policies cover the cost to replace or repair the asset:
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/engineering-Insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Liability" role="tabpanel" aria-labelledby="nav-Liability-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Liability Insurance</h3>
                                            <h6>
                                                Companies are often exposed to legal liabilities arising out of breach of duty of care to the general public and their customers. Key product coverages:
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/liability-insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Employee" role="tabpanel" aria-labelledby="nav-Employee-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Employee Benefit Insurance</h3>
                                            <h6>
                                                There is a range of employee benefits products which provide companies with holistic solutions to manage their employees’ interests:
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/employee-insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Marine" role="tabpanel" aria-labelledby="nav-Marine-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Marine Insurance</h3>
                                            <h6>
                                                Suitable for exporters, importers, traders, manufacturers, contractors of projects, bankers and individuals. A transit policy covers transit by road, rail, air or water. The classifications of transit policies are:
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/marine-insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Crime" role="tabpanel" aria-labelledby="nav-Crime-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Burglary, Crime, Fidelity Insurance</h3>
                                            <h6>
                                                Cover provides peace of mind against financial loss to business owners against incidents of theft or burglary. Crime and Fidelity cover provides protection for financial losses caused due to employee dishonesty, theft or fraud.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/crime-insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Credit" role="tabpanel" aria-labelledby="nav-Credit-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Credit Insurance</h3>
                                            <h6>
                                                This policy protects amounts receivable against non-payment from the buyer due to insolvency, protracted default and political risks (for exports only). This policy is offered by insurers for domestic trade and exports and is an effective tool indemnifying
                                                business risks.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/credit-insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button data-value="Fire Insurance" className="nav-link active" id="nav-Fire-tab" data-bs-toggle="tab" data-bs-target="#nav-Fire" type="button" role="tab" aria-controls="nav-Fire" aria-selected="true">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/fireIcon.png" /></center>
                                        </div>
                                        Fire Insurance
                                    </button>
                                    <button data-value="Engineering Insurance" className="nav-link" id="nav-Engineering-tab" data-bs-toggle="tab" data-bs-target="#nav-Engineering" type="button" role="tab" aria-controls="nav-Engineering" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/engineeringIcon.png" /></center>
                                        </div>
                                        Engineering Insurance
                                    </button>
                                    <button data-value="Liability Insurance" className="nav-link" id="nav-Liability-tab" data-bs-toggle="tab" data-bs-target="#nav-Liability" type="button" role="tab" aria-controls="nav-Liability" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/liabilityIcon.png" /></center>
                                        </div>
                                        Liability Insurance
                                    </button>
                                    <button data-value="Employee Benefit Insurance" className="nav-link" id="nav-Employee-tab" data-bs-toggle="tab" data-bs-target="#nav-Employee" type="button" role="tab" aria-controls="nav-Employee" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/employeeIcon.png" /></center>
                                        </div>
                                        Employee Benefit Insurance
                                    </button>
                                    <button data-value="Marine Insurance" className="nav-link" id="nav-Marine-tab" data-bs-toggle="tab" data-bs-target="#nav-Marine" type="button" role="tab" aria-controls="nav-Marine" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/marineIcon.png" /></center>
                                        </div>
                                        Marine Insurance
                                    </button>
                                    <button data-value="Burglary, Crime, Fidelity Insurance" className="nav-link" id="nav-Crime-tab" data-bs-toggle="tab" data-bs-target="#nav-Crime" type="button" role="tab" aria-controls="nav-Crime" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/cimeIcon.png" /></center>
                                        </div>
                                        Burglary, Crime, Fidelity Insurance
                                    </button>
                                    <button data-value="Credit Insurance" className="nav-link" id="nav-Credit-tab" data-bs-toggle="tab" data-bs-target="#nav-Credit" type="button" role="tab" aria-controls="nav-Credit" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/creditIcon.png" /></center>
                                        </div>
                                        Credit Insurance
                                    </button>
                                </div>
                            </nav>
                            <div className="contact inpageformM">
                                <div className="php-email-form">
                                    <form onSubmit={(e)=>handleSubmit(e)}>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)} required />
                                                <span className="text-danger name_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="text" className="form-control" name="phone_no" id="phone_no" placeholder="Phone Number" onChange={(e)=>setPhone(e.target.value)} required />
                                                <span className="text-danger phone_no_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Company Email Id" onChange={(e)=>setEmail(e.target.value)} required />
                                                <span className="text-danger email_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="text" name="company_name" className="form-control" id="company_name" placeholder="Company Name" onChange={(e)=>setCompanyName(e.target.value)} />
                                                <span className="text-danger company_name_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="text" name="industry_type" className="form-control" id="industry_type" placeholder="Industry Type" onChange={(e)=>setIndustryType(e.target.value)} />
                                                <span className="text-danger industry_type_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <div className="text-center"><button type="button" id="submit">Submit&nbsp; <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button></div>
                                            </div>
                                            <div className="">
                                                {loading && <div className="loading alert">Please wait....</div>}
                                                {error && <div className="error-message alert">Message can't be sent. - this will change to - Oops Seem like there is an issue, Please check back soon !</div>}
                                                {success && <div className="sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
        </>
        
    );
}
export default CommercialInsurance;