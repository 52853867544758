import { React, useState } from "react";
import { Helmet } from 'react-helmet';
import { letUsContactYou } from "../api";
function PersonalInsurance() {

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if(isValid){
            console.log(isValid);
            console.log(name);
            console.log(email);
            console.log(phone);
            setLoading(true);
            let result = await letUsContactYou({name:name,phone:phone,email:email});
            if(result){
                console.log(result);
                if(result=='success'){
                    setSuccess(true);
                }
                else{
                    setError(true);
                }
                // setResponseMsg(result.msg);
            }            
        }
        
    };

    const validateForm=()=> {
        // $('.text-danger').html('');
        // var name = $('#name').val();
        // var phone_no = $('#phone_no').val();
        // var email = $('#email').val();
        // var industry_type = $('#industry_type').val();
        // var company_name = $('#company_name').val();
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var valid = true;
        if (name === "") {
            // $('.name_err').html('Name required');
            valid = false;
        }
        if (phoneno.test(phone) === false) {
            // $('.phone_no_err').html('10 digit phone no required');
            valid = false;
        }
        if (mailformat.test(email) === false) {
            // $('.email_err').html('Valid email id required');
            valid = false;
        }
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Welcome - Personal Insurance</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>

            <main id="main" style={{ paddingTop: 90 }}>
                <section id="" className="inpageMain">
                    <div className="container">
                        <div className="row">
                            <div className="modal_sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>
                            <div className="col-lg-12">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-Health" role="tabpanel" aria-labelledby="nav-Health-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Health Insurance</h3>
                                                <h6>
                                                    Health insurance provides financial assistance at the time of medical exigency. We offer following solutions:
                                                </h6>
                                                <ul>
                                                    <li> Individual health insurance</li>
                                                    <li> Family floater health insurance</li>
                                                    <li> Health Super Top-Up</li>
                                                    <li> Critical Illness</li>
                                                    <li> Individual personal accident insurance</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/Health-Insurance.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Motor" role="tabpanel" aria-labelledby="nav-Motor-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Motor Insurance</h3>
                                                <h6>
                                                    Motor insurance must be taken by a vehicle owner whose vehicle is registered with the Regional Transport Authority in India:
                                                </h6>
                                                <ul>
                                                    <li>The cover includes comprehensive coverage with own damage, third party liability, etc.</li>
                                                    <li>There are several variants of motor car insurance policies such as zero depreciation plans, and plans with different coverage levels consumables, tires, key and engine protection.</li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/Motor-Insurance.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Travel" role="tabpanel" aria-labelledby="nav-Travel-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Home & Travel Insurance</h3>
                                                <h6>
                                                    Home insurance, or property insurance, covers your residence against natural calamities. It’s natural to want the best for your home, because home is where the heart is. However, unforeseen risks can come knocking at any time.
                                                </h6>
                                                <h6>
                                                    Travelling around the world is a thrilling experience. However, no matter how precisely we plan our trip, things tend to go haywire. Travel insurance provides various options that offers coverage against lost passport, lost checked-in baggage, trip delays
                                                    & more.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img class="img-fluid" src="assets/img/Travel-Insurance.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button data-value="Health Insurance" className="nav-link active" id="nav-Health-tab" data-bs-toggle="tab" data-bs-target="#nav-Health" type="button" role="tab" aria-controls="nav-Health" aria-selected="true">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/healthIcon.png" /></center>
                                            </div>
                                            Health Insurance
                                        </button>
                                        <button data-value="Motor Insurance" className="nav-link" id="nav-Motor-tab" data-bs-toggle="tab" data-bs-target="#nav-Motor" type="button" role="tab" aria-controls="nav-Motor" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/motorIcon.png" /></center>
                                            </div>
                                            Motor Insurance
                                        </button>
                                        <button data-value="Home & Travel Insurance" className="nav-link" id="nav-Travel-tab" data-bs-toggle="tab" data-bs-target="#nav-Travel" type="button" role="tab" aria-controls="nav-Travel" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/travelIcon.png" /></center>
                                            </div>
                                            Home & Travel Insurance
                                        </button>




                                    </div>
                                </nav>
                                <div className="contact inpageformM">
                                    <div className="php-email-form">
                                        <form onSubmit={(e)=>handleSubmit(e)}>
                                            <div className="row">
                                                <div className="col-md-3 form-group">
                                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)} required />
                                                    <span className="text-danger name_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <input type="text" className="form-control" name="phone_no" id="phone_no" placeholder="Phone Number" onChange={(e)=>setPhone(e.target.value)} required />
                                                    <span className="text-danger phone_no_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Company Email Id" onChange={(e)=>setEmail(e.target.value)} required />
                                                    <span className="text-danger email_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group inpageformp">
                                                    <div className="text-center"><button type="button" id="submit">Submit&nbsp; <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button></div>
                                                </div>
                                                <div className="">
                                                    {loading && <div className="loading alert">Please wait....</div>}
                                                    {error && <div className="error-message alert">Message can't be sent. - this will change to - Oops Seem like there is an issue, Please check back soon !</div>}
                                                    {success && <div className="sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>

                        </div>




                    </div>
                </section>
            </main>
        </>

    );
}
export default PersonalInsurance;