import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import { letUsContactYou } from "../api";
import { Helmet } from "react-helmet";

function Home() {
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [industryType, setIndustryType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = validateForm();
    if (isValid) {
      console.log(isValid);
      console.log(name);
      console.log(email);
      console.log(phone);
      console.log(companyName);
      console.log(industryType);
      setLoading(true);
      let result = await letUsContactYou({
        name: name,
        phone: phone,
        email: email,
        companyName: companyName,
        industryType: industryType,
      });
      if (result) {
        console.log(result);
        if (result == "success") {
          setSuccess(true);
        } else {
          setError(true);
        }
        // setResponseMsg(result.msg);
      }
    }
  };

  const validateForm = () => {
    // $('.text-danger').html('');
    // var name = $('#name').val();
    // var phone_no = $('#phone_no').val();
    // var email = $('#email').val();
    // var industry_type = $('#industry_type').val();
    // var company_name = $('#company_name').val();
    var mailformat =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    var valid = true;
    if (name === "") {
      // $('.name_err').html('Name required');
      valid = false;
    }
    if (phoneno.test(phone) === false) {
      // $('.phone_no_err').html('10 digit phone no required');
      valid = false;
    }
    if (mailformat.test(email) === false) {
      // $('.email_err').html('Valid email id required');
      valid = false;
    }
    if (industryType === "") {
      // $('.industry_type_err').html('Industry type required');
      valid = false;
    }
    if (companyName === "") {
      // $('.company_name_err').html('Company name required');
      valid = false;
    }
    return valid;
  };

  return (
    <>
      <Helmet>
        <title>BridgeInsure - Insurance Done Right</title>
        <meta content="" name="description" />
        <meta content="" name="keywords" />
      </Helmet>
      <section id="SliderMain" style={{ paddingTop: "6%" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="SliderContect">
                <h3>Protect what you worked so hard to build</h3>
                <h4>get complete protection for your business</h4>
                <p>
                  At BridgeInsure we are passionate about de-risking your
                  business. Insurance needs change basis nature, size and stage
                  of commercial enterprise. Let us handle the tough task!
                </p>
                <p style={{ marginBottom: 0 }}>
                  {/* Have existing insurance, not sure what it covers? No worries, Let our expert do a detailed <NavLink to="/gap-analysis" style={{padding:0, background:'none', color: 'blue', textDecoration:'underline'}}>GAP Analysis!</NavLink> */}
                  Have existing insurance, not sure what it covers? No worries,
                  Let our expert do a detailed{" "}
                  <NavLink
                    to="/gap-analysis"
                    style={{
                      padding: 0,
                      background: "none",
                      color: "blue",
                      textDecoration: "underline",
                    }}
                  >
                    GAP Analysis!
                  </NavLink>
                </p>
              </div>
            </div>

            <div className="col-sm-6">
              <center>
                <img className="img-fluid" src="assets/img/insurance.png" />
              </center>
            </div>
          </div>
        </div>
      </section>
      <main id="main">
        <section id="contact" className="contact">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 php-email-form">
                <div
                  style={{ paddingBottom: 0 }}
                  className="section-title aos-init aos-animate"
                  data-aos="fade-up"
                >
                  <h2 style={{ textAlign: "left", color: "#fff" }}>
                    LET US CONTACT YOU!
                  </h2>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="row">
                    <div className="col-md-3 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <span className="text-danger name_err"></span>
                    </div>
                    <div className="col-md-3 form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="phone_no"
                        id="phone_no"
                        placeholder="Phone Number"
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      <span className="text-danger phone_no_err"></span>
                    </div>
                    <div className="col-md-3 form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Company Email Id"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <span className="text-danger email_err"></span>
                    </div>
                    <div className="col-md-3 form-group">
                      <input
                        type="text"
                        name="company_name"
                        className="form-control"
                        id="company_name"
                        onChange={(e) => setCompanyName(e.target.value)}
                        placeholder="Company Name"
                      />
                      <span className="text-danger company_name_err"></span>
                    </div>
                    <div className="col-md-3 form-group">
                      <input
                        type="text"
                        name="industry_type"
                        className="form-control"
                        id="industry_type"
                        onChange={(e) => setIndustryType(e.target.value)}
                        placeholder="Industry Type"
                      />
                      <span className="text-danger industry_type_err"></span>
                    </div>
                    <div className="col-md-3 form-group">
                      <div className="text-center">
                        <button type="submit" id="submit">
                          Submit{" "}
                          <i
                            className="fa fa-paper-plane-o"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div className="">
                      {loading && (
                        <div className="loading alert">Please wait....</div>
                      )}
                      {error && (
                        <div className="error-message alert">
                          Message can't be sent. - this will change to - Oops
                          Seem like there is an issue, Please check back soon !
                        </div>
                      )}
                      {success && (
                        <div className="sent-message alert">
                          Your message has been sent. Thank you! - this will
                          change to Thank You for your Enquiry, Our expert will
                          be in touch with you soon !
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section id="plan" className="plan">
          <div className="container">
            <div
              className="section-title aos-init aos-animate"
              data-aos="fade-up"
            >
              <h2 style={{ textAlign: "left" }}>
                OUR<span> APPROACH</span>
              </h2>
              <h4 style={{ textAlign: "left" }}>
                We operate with UNIQUE Model
              </h4>
            </div>

            <div className="row">
              <div className="col-lg-4">
                <center>
                  <img className="img-fluid" src="assets/img/unique.png" />
                </center>
              </div>
              <div className="col-lg-8 uniqueMain">
                <div className="unique1">
                  <h5>Understand business and process</h5>
                </div>
                <div className="unique2">
                  <h5>Need identification basis risk review</h5>
                </div>
                <div className="unique3">
                  <h5>
                    Inspect existing policies to identify gap in risk cover &
                    optimize cost
                  </h5>
                </div>
                <div className="unique4">
                  <h5>Quantify Gap - Propose solutions</h5>
                </div>
                <div className="unique5">
                  <h5>Undertake implementation of agreed proposal</h5>
                </div>
                <div className="unique6">
                  <h5>Extend post sales value added services</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="" className="strengthMain">
          <div className="container">
            <div
              className="section-title aos-init aos-animate"
              data-aos="fade-up"
            >
              <h2 style={{ textAlign: "left" }}>
                KEY<span> STRENGTHS</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="strengthBox1">
                  <h4>
                    Team of professionals with <span>50+ years</span> of work
                    experience
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="strengthBox2">
                  <h4>
                    <span>GAP Analysis</span> helps in taking an informed
                    decision
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="strengthBox3">
                  <h4>
                    <span>Advisory and provisions of various options</span> to
                    ensure optimal decision making
                  </h4>
                </div>
              </div>
            </div>
            <div className="row strengthBoxMain">
              <div className="col-lg-2"></div>
              <div className="col-lg-4">
                <div className="strengthBox2">
                  <h4>
                    <span>Dedicated team</span> of expert advisors to handle
                    queries and claims
                  </h4>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="strengthBox3">
                  <h4>
                    <span>Corporate and individual</span> solutions offered
                  </h4>
                </div>
              </div>
              <div className="col-lg-2"></div>
            </div>
          </div>
        </section>

        <section id="why" className="why">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 whyLcontent">
                <h4>Why Insure Right</h4>
                <p>
                  If you’re still reading this, we are pretty sure you already
                  recognize the benefit of using BridgeInsure. However, if you
                  are still not convinced, here’s a few extra points to change
                  your mind
                </p>
                <ul>
                  <li>Support for additional queries</li>
                  <li>Claim Support for when you have to make a claim</li>
                  <li>Moral Support for when you need reassurance</li>
                </ul>
              </div>
              <div className="col-lg-6 whyRcontent">
                <div className="whyRcontentBox">
                  <div className="whyRcontentIcon">
                    <img className="img-fluid" src="assets/img/InsIcon.svg" />
                  </div>
                  <div className="whyRcontentC">
                    <h4>Buy Insurance</h4>
                    <p>
                      Use our intuitive platform to understand, compare & buy a
                      comprehensive plan
                    </p>
                  </div>
                </div>
                <div className="whyRcontentBox">
                  <div className="whyRcontentIcon">
                    <img className="img-fluid" src="assets/img/InsIcon.svg" />
                  </div>
                  <div className="whyRcontentC">
                    <h4>Understand your Insurance</h4>
                    <p>
                      If you already have a health plan, you can see what it’s
                      lacking here
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="counterMain" className="counterMain">
          <div className="container">
            <div className="counterBg">
              <div className="row">
                <div className="col-lg-4 counterBox">
                  <div className="">
                    <div className="counterBoxIcon">
                      <img className="img-fluid" src="assets/img/count1.svg" />
                    </div>
                    <div className="counterBoxC">
                      <h4>50</h4>
                      <p>
                        Top Insurance Advisors on standby to answer your queries
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 counterBox">
                  <div className="">
                    <div className="counterBoxIcon">
                      <img className="img-fluid" src="assets/img/count2.svg" />
                    </div>
                    <div className="counterBoxC">
                      <h4>50,000+</h4>
                      <p>
                        People have sought BridgeInsure advice before making
                        their insurance purchase
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 counterBox">
                  <div className="">
                    <div className="counterBoxIcon">
                      <img className="img-fluid" src="assets/img/count3.svg" />
                    </div>
                    <div className="counterBoxC">
                      <h4>99%</h4>
                      <p>Our success rate</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
export default Home;
