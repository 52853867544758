import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Contact,
  GapAnalysis,
  CommercialInsurance,
  EmployeeBenefit,
  PersonalInsurance,
  Specialized,
  PackageProducts,
  ProfessionalIndemnity,
  PrivacyPolicy,
  TermsOfUse,
  GrievanceRedressalPolicy,
  Analysis,
} from "./components";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <header id="header" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-between">
        <div className="logo">
          <a href="/">
            {" "}
            <img src="assets/img/logo.png" alt="" className="img-fluid" />{" "}
          </a>
        </div>
        <Navigation />
      </div>
    </header>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/gap-analysis" element={<GapAnalysis />} />
      <Route path="/analysis" element={<Analysis />} />
      <Route path="/commercial-insurance" element={<CommercialInsurance />} />
      <Route path="/employee-benefit" element={<EmployeeBenefit />} />
      <Route path="/personal-insurance" element={<PersonalInsurance />} />
      <Route path="/specialized" element={<Specialized />} />
      <Route path="/package-products" element={<PackageProducts />} />
      <Route
        path="/professional-indemnity"
        element={<ProfessionalIndemnity />}
      />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route
        path="/grievance-redressal-policy"
        element={<GrievanceRedressalPolicy />}
      />
    </Routes>
    <Footer />
  </Router>,
  document.getElementById("root")
);
