import { React, useState } from "react";
import { Helmet } from 'react-helmet';
import { letUsContactYou } from "../api";
function PackageProducts() {

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if(isValid){
            console.log(isValid);
            console.log(name);
            console.log(email);
            console.log(phone);
            setLoading(true);
            let result = await letUsContactYou({name:name,phone:phone,email:email});
            if(result){
                console.log(result);
                if(result=='success'){
                    setSuccess(true);
                }
                else{
                    setError(true);
                }
                // setResponseMsg(result.msg);
            }            
        }
        
    };

    const validateForm=()=> {
        // $('.text-danger').html('');
        // var name = $('#name').val();
        // var phone_no = $('#phone_no').val();
        // var email = $('#email').val();
        // var industry_type = $('#industry_type').val();
        // var company_name = $('#company_name').val();
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var valid = true;
        if (name === "") {
            // $('.name_err').html('Name required');
            valid = false;
        }
        if (phoneno.test(phone) === false) {
            // $('.phone_no_err').html('10 digit phone no required');
            valid = false;
        }
        if (mailformat.test(email) === false) {
            // $('.email_err').html('Valid email id required');
            valid = false;
        }
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Welcome - Package Products</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>

            <main id="main" style={{ paddingTop: 90 }}>
                <section id="" className="inpageMain">
                    <div className="container">
                        <div className="row">
                            <div className="modal_sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>
                            <div className="col-lg-12">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-Jeweler" role="tabpanel" aria-labelledby="nav-Jeweler-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Jeweler’s Block</h3>
                                                <h6>
                                                    This policy covers risks of jewelers whose business involves sale of articles of high value like jewelry, gold and silver articles, diamonds and precious stones, wrist watches etc. The trade involves stocking these items in large quantity and moving them
                                                    between different premises.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/Jeweler-Insurance.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Products" role="tabpanel" aria-labelledby="nav-Products-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Schools/ Educational Institutes</h3>
                                                <h6>
                                                This package policy covers educational institutes like Schools, Colleges, Universities, students, etc. It covers the protection to the school building, and its content from any damage, unexpected accidents, personal accidents to employees, and Public Liability, and also covers students enrolled in the institute.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/schools.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="nav-Accountants" role="tabpanel" aria-labelledby="nav-Accountants-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Hospitals</h3>
                                                <h6>
                                                    This policy covers risks of jewelers whose business involves sale of articles of high value like jewelry, gold and silver articles, diamonds and precious stones, wrist watches etc. The trade involves stocking these items in large quantity and moving them
                                                    between different premises.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/hospital.jpg" /></center>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="tab-pane fade" id="nav-Gym" role="tabpanel" aria-labelledby="nav-Gym-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Gym</h3>
                                                <h6>
                                                This policy covers significant risks that gyms, fitness centers, or health clubs are exposed to like public liability, product liability, employee practices liability, property coverage, equipment coverage, property coverage, and professional liability.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/gym.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Policy" role="tabpanel" aria-labelledby="nav-Policy-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Shopkeeprs Policy</h3>
                                                <h6>
                                                This insurance is available to independent shopkeepers, family business owners, multiple shop owners, etc. The type of shop includes electronic, personal lifestyle, grocery, eatables, repair shops etc. This policy provides protection from Fire, Burglary, machinery breakdown, personal accidents, public liability, employers’ liability, tenants’ legal liability, etc.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/shopkeeprs.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-Restaurant" role="tabpanel" aria-labelledby="nav-Restaurant-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Restaurant/Cloud Kitchen package policy</h3>
                                                <h6>
                                                This policy is created to offer complete protection for enterprises that operate cloud kitchens and restaurants. It especially addresses the special risks connected to these businesses, offering protection for their priceless assets and business activities.
                                                </h6>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/restaurant.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button data-value="Jeweler’s Block" className="nav-link active" id="nav-Jeweler-tab" data-bs-toggle="tab" data-bs-target="#nav-Jeweler" type="button" role="tab" aria-controls="nav-Jeweler" aria-selected="true">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/jewelerIcon.png" /></center>
                                            </div>
                                            Jeweler’s Block
                                        </button>
                                        <button data-value="Schools/ Educational Institutes" className="nav-link" id="nav-Products-tab" data-bs-toggle="tab" data-bs-target="#nav-Products" type="button" role="tab" aria-controls="nav-Products" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/schoolsIcon.png" /></center>
                                            </div>
                                            Schools/ Educational Institutes
                                        </button>
                                        {/* <button data-value="Hospitals" className="nav-link" id="nav-Accountants-tab" data-bs-toggle="tab" data-bs-target="#nav-Accountants" type="button" role="tab" aria-controls="nav-Accountants" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/hospitalsIcon.png" /></center>
                                            </div>
                                            Hospitals
                                        </button> */}
                                        <button data-value="Gym" className="nav-link" id="nav-Gym-tab" data-bs-toggle="tab" data-bs-target="#nav-Gym" type="button" role="tab" aria-controls="nav-Gym" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/gymIcon.png" /></center>
                                            </div>
                                            Gym
                                        </button>
                                        <button data-value="Shopkeeprs Policy" className="nav-link" id="nav-Policy-tab" data-bs-toggle="tab" data-bs-target="#nav-Policy" type="button" role="tab" aria-controls="nav-Policy" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/policyIcon.png" /></center>
                                            </div>
                                            Shopkeeprs Policy
                                        </button>
                                        <button data-value="Restaurant/Cloud Kitchen package policy" className="nav-link" id="nav-Restaurant-tab" data-bs-toggle="tab" data-bs-target="#nav-Restaurant" type="button" role="tab" aria-controls="nav-Restaurant" aria-selected="false">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/restaurantIcon.png" /></center>
                                            </div>
                                            Restaurant/Cloud Kitchen package policy
                                        </button>
                                    </div>
                                </nav>

                                <div className="contact inpageformM">
                                    <div className="php-email-form">
                                        <form onSubmit={(e)=>handleSubmit(e)}>
                                            <div className="row">
                                                <div className="col-md-3 form-group">
                                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)} required />
                                                    <span className="text-danger name_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <input type="text" className="form-control" name="phone_no" id="phone_no" placeholder="Phone Number" onChange={(e)=>setPhone(e.target.value)} required />
                                                    <span className="text-danger phone_no_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Company Email Id" onChange={(e)=>setEmail(e.target.value)} required />
                                                    <span className="text-danger email_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group inpageformp">
                                                    <div className="text-center"><button type="button" id="submit">Submit&nbsp; <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button></div>
                                                </div>
                                                <div className="">
                                                    {loading && <div className="loading alert">Please wait....</div>}
                                                    {error && <div className="error-message alert">Message can't be sent. - this will change to - Oops Seem like there is an issue, Please check back soon !</div>}
                                                    {success && <div className="sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>

    );
}
export default PackageProducts;