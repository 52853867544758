import { React, useState } from "react";
import { Helmet } from 'react-helmet';
import { letUsContactYou } from "../api";
function EmployeeBenefit() {

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [industryType, setIndustryType] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if(isValid){
            console.log(isValid);
            console.log(name);
            console.log(email);
            console.log(phone);
            console.log(companyName);
            console.log(industryType);
            setLoading(true);
            let result = await letUsContactYou({name:name,phone:phone,email:email,companyName:companyName, industryType:industryType});
            if(result){
                console.log(result);
                if(result=='success'){
                    setSuccess(true);
                }
                else{
                    setError(true);
                }
                // setResponseMsg(result.msg);
            }            
        }
        
    };

    const validateForm=()=> {
        // $('.text-danger').html('');
        // var name = $('#name').val();
        // var phone_no = $('#phone_no').val();
        // var email = $('#email').val();
        // var industry_type = $('#industry_type').val();
        // var company_name = $('#company_name').val();
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var valid = true;
        if (name === "") {
            // $('.name_err').html('Name required');
            valid = false;
        }
        if (phoneno.test(phone) === false) {
            // $('.phone_no_err').html('10 digit phone no required');
            valid = false;
        }
        if (mailformat.test(email) === false) {
            // $('.email_err').html('Valid email id required');
            valid = false;
        }
        if (industryType === "") {
            // $('.industry_type_err').html('Industry type required');
            valid = false;
        }
        if (companyName === "") {
            // $('.company_name_err').html('Company name required');
            valid = false;
        }
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Welcome - Employee Benefit</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>

        <main id="main" style={{ paddingTop: 90 }}>
            <section id="" className="inpageMain">
                <div className="container">
                    <div className="row">
                        <div className="modal_sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>
                        <div className="col-lg-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-Fire" role="tabpanel" aria-labelledby="nav-Fire-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Group Health Insurance</h3>
                                            <h6>
                                                A Group Health Insurance is a type of health insurance plan that covers for a group of people who work under the same organization. This is often offered as a valuable benefit for employees as the premium for the same is borne by the employer.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/Health-Insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Engineering" role="tabpanel" aria-labelledby="nav-Engineering-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Group Term Life</h3>
                                            <h6>
                                                Group Term Life Insurance Plans, are designed to offer life insurance to a group of people under a single policy.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img class="img-fluid" src="assets/img/term-life-Insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Liability" role="tabpanel" aria-labelledby="nav-Liability-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Group Personal Accident</h3>
                                            <h6>
                                                Group personal accident insurance, is a group insurance policy that provides protection to members of group against accidental death or disability
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/accident- insurance.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Employee" role="tabpanel" aria-labelledby="nav-Employee-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Group Gratuity</h3>
                                            <h6>
                                                A Group plan enabling employers to build gratuity funds systematically to meet gratuity payouts to employees, thereby avoiding uncertain financial strain organization.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/gratuity.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Marine" role="tabpanel" aria-labelledby="nav-Marine-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Leave Encashment</h3>
                                            <h6>
                                                Leave encashment is a benefit offered employers to employees, in which an employee can accumulate his or her leaves over a period of time. The accumulated leaves are paid in lump sum to your employees once they are no longer a part of your organization.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img className="img-fluid" src="assets/img/Leave-encashment.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-Crime" role="tabpanel" aria-labelledby="nav-Crime-tab">
                                    <div className="row">
                                        <div className="col-lg-7 inpageC">
                                            <h3 data-aos="fade-up" className="aos-init aos-animate">Group Pension Fund</h3>
                                            <h6>
                                                The Group Pension or Superannuation Plans are designed to cater to an employee's retirement needs, typically offered by employers.
                                            </h6>
                                        </div>
                                        <div className="col-lg-5 inpageI">
                                            <center><img class="img-fluid" src="assets/img/pension-fund.jpg" /></center>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <nav>
                                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button data-value="Group Health Insurance (GMC)" className="nav-link active" id="nav-Fire-tab" data-bs-toggle="tab" data-bs-target="#nav-Fire" type="button" role="tab" aria-controls="nav-Fire" aria-selected="true">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/healthIcon.png" /></center>
                                        </div>
                                        Group Health Insurance (GMC)
                                    </button>
                                    <button data-value="Group Term Life" className="nav-link" id="nav-Engineering-tab" data-bs-toggle="tab" data-bs-target="#nav-Engineering" type="button" role="tab" aria-controls="nav-Engineering" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/groupIcon.png" /></center>
                                        </div>
                                        Group Term Life
                                    </button>
                                    <button data-value="Group Personal Accident" className="nav-link" id="nav-Liability-tab" data-bs-toggle="tab" data-bs-target="#nav-Liability" type="button" role="tab" aria-controls="nav-Liability" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/accidentIcon.png" /></center>
                                        </div>
                                        Group Personal Accident
                                    </button>
                                    <button data-value="Group Gratuity" className="nav-link" id="nav-Employee-tab" data-bs-toggle="tab" data-bs-target="#nav-Employee" type="button" role="tab" aria-controls="nav-Employee" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/gratuityIcon.png" /></center>
                                        </div>
                                        Group Gratuity
                                    </button>
                                    <button data-value="Leave Encashment" className="nav-link" id="nav-Marine-tab" data-bs-toggle="tab" data-bs-target="#nav-Marine" type="button" role="tab" aria-controls="nav-Marine" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/leaveIcon.png" /></center>
                                        </div>
                                        Leave Encashment
                                    </button>
                                    <button data-value="Group Pension Fund" className="nav-link" id="nav-Crime-tab" data-bs-toggle="tab" data-bs-target="#nav-Crime" type="button" role="tab" aria-controls="nav-Crime" aria-selected="false">
                                        <div className="tabIconBox">
                                            <center><img src="assets/img/icons/fundIcon.png" /></center>
                                        </div>
                                        Group Pension Fund
                                    </button>
                                </div>
                            </nav>


                            <div className="contact inpageformM">
                                <div className="php-email-form">
                                    <form onSubmit={(e)=>handleSubmit(e)}>
                                        <div className="row">
                                            <div className="col-md-3 form-group">
                                                <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)} required />
                                                    <span className="text-danger name_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="text" className="form-control" name="phone_no" id="phone_no" placeholder="Phone Number" onChange={(e)=>setPhone(e.target.value)} required />
                                                    <span className="text-danger phone_no_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="email" className="form-control" name="email" id="email" placeholder="Company Email Id" onChange={(e)=>setEmail(e.target.value)} required />
                                                    <span className="text-danger email_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="text" name="company_name" className="form-control" id="company_name" placeholder="Company Name" onChange={(e)=>setCompanyName(e.target.value)} />
                                                    <span className="text-danger company_name_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <input type="text" name="industry_type" className="form-control" id="industry_type" placeholder="Industry Type" onChange={(e)=>setIndustryType(e.target.value)} />
                                                    <span className="text-danger industry_type_err"></span>
                                            </div>
                                            <div className="col-md-3 form-group">
                                                <div className="text-center"><button type="button" id="submit">Submit&nbsp; <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button></div>
                                            </div>
                                            <div className="">
                                                {loading && <div className="loading alert">Please wait....</div>}
                                                {error && <div className="error-message alert">Message can't be sent. - this will change to - Oops Seem like there is an issue, Please check back soon !</div>}
                                                {success && <div className="sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
        </>

    );
}
export default EmployeeBenefit;