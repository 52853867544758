import { React, useState } from "react";
import { Helmet } from 'react-helmet';

function TermsOfUse() {

    return (
        <>
            <Helmet>
                <title>Welcome - Terms of Use</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>


            <main id="main" style={{ paddingTop: 70 }}>
                <section id="" className="inpageMain">
                    <div className="container">
                        <div className="inpageHeadingTital">
                            <h4>Terms of Use</h4>
                            <hr />
                        </div>
                        <div className="row inpageContentTag">
                            <div className="col-sm-12">
                                <p style={{ fontWeight: 500, fontStyle: 'italic' }}>
                                    This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic
                                    record is generated by a computer system and does not require any physical or digital signatures to make the terms of this document binding.
                                </p>
                                <h4>PLEASE READ THE TERMS AND CONDITIONS THOROUGHLY AND CAREFULLY.</h4>
                                <h5>Terms of Use</h5>
                                <p>
                                    BridgeInsure Global Insurance Broker Private Limited (hereinafter referred to as “Bridgeinsure”) operates the website <a href="https://www.bridgeinsure.com">https://www.bridgeinsure.com</a> (hereinafter referred to as “Website”).
                                    Bridgeinsure is a licensed Insurance Broking Company holding a broking license from the Indian Insurance Regulator – Insurance Regulatory and Development Authority of India bearing IRDAI License No. 836 - Direct Broker (Life
                                    & General) - valid till: 19/07/2025 (renews every 3 years). Through Website, Bridgeinsure provides users with information and tools to make it easy to compare, get information and buy insurance policies.
                                </p>
                                <p>
                                    By visiting Website and accessing the information, resources, services, products, and tools we provide, you understand and agree to accept and adhere to the following terms of use as stated in this policy (hereafter referred to as ‘TOU’), along with the
                                    terms and conditions as stated in our <a href="">Privacy Policy</a>.
                                </p>
                                <p>
                                    This TOU is effective from the time you visit our website. We reserve the right, at any time, at our sole discretion, to change or otherwise modify the TOU without prior notice, and your continued access or use of Website signifies your acceptance of
                                    the updated or modified TOU.
                                </p>
                                <h5>Using Website</h5>
                                <p>
                                    By visiting our website and accessing the information, resources, services, products, and tools we provide for you, either directly or indirectly (hereafter referred to as ‘Resources’), you agree to use these Resources only for the purposes intended as
                                    permitted by the terms of this TOU, and/or applicable laws, regulations and generally accepted online practices or guidelines.
                                </p>
                                <p>Wherein, you understand that:</p>
                                <ul>
                                    <li>
                                        In order to access our Resources, you may be required to provide certain information about yourself (such as name, address, contact details, car registration and purchase details, identity proof such as Aadhar ID, etc.). You agree that any information
                                        you provide will always be accurate, correct, and updated.
                                    </li>
                                    <li>
                                        By providing your information you explicitly authorize us to access on your behalf your records from the competent authority, such as Aadhar, RTO, etc. for the purpose of facilitation of your transaction with us.
                                    </li>
                                    <li>
                                        You are responsible for maintaining the confidentiality of any login information associated with any account you use to access our Resources. Accordingly, you are responsible for all activities that occur under your account/s.
                                    </li>
                                    <li>
                                        Accessing (or attempting to access) any of our Resources by any means other than through the means we provide is strictly prohibited. You specifically agree not to access (or attempt to access) any of our Resources through any automated, unethical or
                                        unconventional means.
                                    </li>
                                    <li>
                                        Engaging in any activity that disrupts or interferes with our Resources, including the servers and/or networks to which our Resources are located or connected, is strictly prohibited.
                                    </li>
                                    <li>
                                        Attempting to copy, duplicate, reproduce, sell, trade, or resell our Resources is strictly prohibited.
                                    </li>
                                    <li>
                                        You are solely responsible for any consequences, losses, or damages that we may directly or indirectly incur or suffer due to any unauthorized activities conducted by you, as explained above, and may incur criminal or civil liabilities.
                                    </li>
                                    <li>
                                        No representation or warranty, either expressed or implied, is made nor responsibility of any kind is accepted by Bridgeinsure, its partners or employees either as to the accuracy or completeness of any information stated on the Website. Neither Bridgeinsure
                                        nor any of its affiliates and/or their partners, officers, employees shall in any way be responsible or liable for any losses or damages whatsoever which any person may suffer or incur as a result of acting or otherwise
                                        relying upon anything stated or inferred in or omitted from this presentation.
                                    </li>
                                    <li>
                                        Information found on the Website reflect information that is subject to change without notice. The information on Website is neither intended nor should be considered as an offer or solicitation, or as the basis for any contract, for purchase of any security,
                                        for insurance, loan or other financial instrument.
                                    </li>
                                </ul>
                                <h5>User generated content</h5>
                                <p>
                                    We may provide various open communication tools on our website, such as blog comments, blog posts, public chat, forums, message boards, newsgroups, product ratings and reviews, various social media services, etc. You understand that we do not pre-screen
                                    content posted by users of these various communication tools, which means that if you choose to use these tools to submit any type of content to our website (“user generated content”), then it is your personal responsibility
                                    to use these tools in a responsible and ethical manner. By posting information or otherwise using any open communication tools as mentioned, you agree that you will not upload, post, share, or otherwise distribute any content
                                    that:
                                </p>
                                <ul>
                                    <li>
                                        Is illegal, threatening, defamatory, abusive, harassing, degrading, intimidating, fraudulent, deceptive, invasive, racist, or contains any type of suggestive, inappropriate, or explicit language;
                                    </li>
                                    <li>
                                        Infringes on any trademark, patent, trade secret, copyright, or other proprietary right of any party;
                                    </li>
                                    <li>
                                        Contains any type of unauthorized or unsolicited advertising;
                                    </li>
                                    <li>
                                        Impersonates any person or entity, including any Bridgeinsure employees or representatives.
                                    </li>
                                </ul>
                                <p style={{ marginTop: '1rem' }}>
                                    We do not assume any liability for any user generated content posted by you or any other 3rd party users of our website. We have the right at our sole discretion to remove any content that, we feel in our judgment does not comply with this TOU, along
                                    with any content that we feel is otherwise offensive, harmful, objectionable, inaccurate, or violates any 3rd party copyrights or trademarks.
                                </p>
                                <p>
                                    Any user generated content posted by you using any open communication tools on our website, provided that it doesn’t violate or infringe on any 3rd party copyrights or trademarks, becomes the property of Bridgeinsure, and as such, gives us a perpetual,
                                    irrevocable, worldwide, royalty-free, exclusive license to reproduce, modify, adapt, translate, publish, publicly display and/or distribute as we see fit. This only refers and applies to user generated content as described.
                                    All user Personal Information, provided as part of our registration or purchase process, is covered by our Privacy Policy (as referred above).
                                </p>
                                <h5>IPR and Copyright</h5>
                                <p>
                                    All content and materials available on Website, including but not limited to text, graphics, website name, code, images and logos are either the intellectual property of Bridgeinsure or under licensed use by Bridgeinsure, and are protected by applicable
                                    copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display or transmission of any content on this site is strictly prohibited, unless specifically authorized by
                                    Bridgeinsure.
                                </p>
                                <h5>Indemnification</h5>
                                <p>
                                    You agree to indemnify and hold Bridgeinsure, its parent company, officers, subsidiaries, affiliates, successors, assigns, directors, officers, agents, service providers, suppliers and employees, harmless from any claim or demand, including reasonable
                                    attorney fees and court costs, made by any third party due to or arising out of content submitted by the user, users use of the service, violation of the TOU, breach by the user of any of the representations and warranties
                                    herein, or user’s violation of any rights of another.
                                </p>
                                <h5>Limitation of Warranties</h5>
                                <p>
                                    By using our website, you understand and agree that all Resources we provide are “as is” and “as available”. This means that we do not represent or warrant to you that:
                                </p>
                                <p>The use of our Resources will meet your needs or requirements,</p>
                                <ul>
                                    <li>The use of our Resources will be uninterrupted, timely, secure or free from errors,</li>
                                    <li>The information obtained by using our Resources will be accurate or reliable, and</li>
                                    <li>Any downloads from Website will not harm your computer or device in any way.</li>
                                </ul>
                                <p>
                                    To the maximum extent permitted by law, Bridgeinsure has no liability in relation to or arising out of the Website Information and recommendations. You are responsible for the final choice of your product. Please seek further advice from Bridgeinsure
                                    or the relevant participating insurance companies before choosing your product, if you have any doubts or queries about the same. Furthermore, Bridgeinsure does not guarantee when or if you will actually acquire the product
                                    that you have chosen and does not accept any liability arising out of delay in you acquiring the product you have chosen. You acknowledge and accept that the final issuance of the policy is subject to the underwriting norms
                                    and sole discretion of the insurance company whose policy you have chosen to buy.
                                </p>
                                <h5>Limitation of Liability</h5>
                                <p>
                                    TOU expressly understand and agree that any claim against us shall be limited to the amount you paid, if any. Bridgeinsure will not be liable for any direct, indirect, incidental, consequential or exemplary loss or damages which may be incurred by you
                                    as a result of using our Resources.
                                </p>
                                <p>
                                    UNLESS OTHERWISE EXPRESSED, BRIDGEINSURE EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                                    AND NON-INFRINGEMENT.
                                </p>
                                <h5>Governing Law</h5>
                                <p>
                                    This TOU and the Privacy Policy shall be governed and construed in accordance with the laws of the Republic of India and the courts at Uttar Pradesh shall have exclusive jurisdiction on all matters and disputes arising out of and relating to the Website.
                                </p>
                                <p style={{ fontWeight: 500, fontStyle: 'italic' }}>These Terms of Use were most recently updated on August 1, 2022.</p>

                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>

    );
}
export default TermsOfUse;