import { React, useState } from "react";
import { Helmet } from 'react-helmet';
import { letUsContactYou } from "../api";
function Specialized() {

    const [name, setName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = validateForm();
        if(isValid){
            console.log(isValid);
            console.log(name);
            console.log(email);
            console.log(phone);
            setLoading(true);
            let result = await letUsContactYou({name:name,phone:phone,email:email});
            if(result){
                console.log(result);
                if(result=='success'){
                    setSuccess(true);
                }
                else{
                    setError(true);
                }
                // setResponseMsg(result.msg);
            }            
        }
        
    };

    const validateForm=()=> {
        // $('.text-danger').html('');
        // var name = $('#name').val();
        // var phone_no = $('#phone_no').val();
        // var email = $('#email').val();
        // var industry_type = $('#industry_type').val();
        // var company_name = $('#company_name').val();
        var mailformat = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        var valid = true;
        if (name === "") {
            // $('.name_err').html('Name required');
            valid = false;
        }
        if (phoneno.test(phone) === false) {
            // $('.phone_no_err').html('10 digit phone no required');
            valid = false;
        }
        if (mailformat.test(email) === false) {
            // $('.email_err').html('Valid email id required');
            valid = false;
        }
        return valid;
    }

    return (
        <>
            <Helmet>
                <title>Welcome - Specialized</title>
                <meta content="" name="description" />
                <meta content="" name="keywords" />
            </Helmet>

            <main id="main" style={{ paddingTop: 90 }}>
                <section id="" className="inpageMain">
                    <div className="container">
                        <div className="row">
                            <div className="modal_sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>
                            <div className="col-lg-12">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-Specialized" role="tabpanel" aria-labelledby="nav-Specialized-tab">
                                        <div className="row">
                                            <div className="col-lg-7 inpageC">
                                                <h3 data-aos="fade-up" className="aos-init aos-animate">Specialized Offerings</h3>
                                                <h6>
                                                    Specialized offerings and Value-Added Products Special situations like:
                                                </h6>
                                                <ul>
                                                    <li>Tax liability</li>
                                                    <li>M&A - W&I – Warranties and Indemnity</li>
                                                    <li>IPO listing - POSI – Public offering of securities insurance cover </li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-5 inpageI">
                                                <center><img className="img-fluid" src="assets/img/Specialized-Offerings.jpg" /></center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button data-value="Specialized Offerings" className="nav-link active" id="nav-Specialized-tab" data-bs-toggle="tab" data-bs-target="#nav-Specialized" type="button" role="tab" aria-controls="nav-Specialized" aria-selected="true">
                                            <div className="tabIconBox">
                                                <center><img src="assets/img/icons/offerIcon.png" /></center>
                                            </div>
                                            Specialized Offerings
                                        </button>
                                    </div>
                                </nav>

                                <div className="contact inpageformM">
                                    <div className="php-email-form">
                                        <form onSubmit={(e)=>handleSubmit(e)}>
                                            <div className="row">
                                                <div className="col-md-3 form-group">
                                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" onChange={(e)=>setName(e.target.value)} required />
                                                    <span className="text-danger name_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <input type="text" className="form-control" name="phone_no" id="phone_no" placeholder="Phone Number" onChange={(e)=>setPhone(e.target.value)} required />
                                                    <span className="text-danger phone_no_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <input type="email" className="form-control" name="email" id="email" placeholder="Company Email Id" onChange={(e)=>setEmail(e.target.value)} required />
                                                    <span className="text-danger email_err"></span>
                                                </div>
                                                <div className="col-md-3 form-group inpageformp">
                                                    <div className="text-center"><button type="button" id="submit">Submit&nbsp; <i className="fa fa-paper-plane-o" aria-hidden="true"></i></button></div>
                                                </div>
                                                <div className="">
                                                    {loading && <div className="loading alert">Please wait....</div>}
                                                    {error && <div className="error-message alert">Message can't be sent. - this will change to - Oops Seem like there is an issue, Please check back soon !</div>}
                                                    {success && <div className="sent-message alert">Your message has been sent. Thank you! - this will change to Thank You for your Enquiry, Our expert will be in touch with you soon !</div>}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>

    );
}
export default Specialized;